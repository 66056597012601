







import { Component, Vue } from 'vue-property-decorator'
import mobile from './h5.vue'
import pc from './pc.vue'

@Component({
  name: 'usdt5',
  components: {
    mobile,
    pc
  }
})
export default class extends Vue {
  get isMobileH5 () {
    return window.screen.availWidth <= 900 && window.screen.availWidth >= 310
  }
}
